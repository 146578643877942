import "./App.css";
import { useMergeLink } from "@mergeapi/react-merge-link";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const newUserUUID = uuidv4();

const fetch = require("node-fetch");

export const apiURLForPath = (path) => {
  var baseURL = "https://api.merge.dev/api";

  if (path.charAt(0) !== "/") {
    return `${baseURL}/${path}`;
  }
  return baseURL + path;
};

const App = () => {
  const [linkToken, setLinkToken] = useState();

  useEffect(() => {
    fetch(apiURLForPath("integrations/create-link-token"), {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.API_KEY}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        end_user_email_address: "Test Email",
        end_user_organization_name: "Test Name",
        end_user_origin_id: newUserUUID,
        categories: ["hris", "ats", "accounting", "ticketing", "crm"],
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setLinkToken(data.link_token);
      });
  }, []);

  return linkToken ? <Component linkToken={linkToken} /> : null;
};

const Component = ({ linkToken }) => {
  const [accountToken, setAccountToken] = useState();

  const { open, isReady } = useMergeLink({
    linkToken: linkToken,
    onSuccess: (publicToken) => {
      fetch(`https://api.merge.dev/api/integrations/account-token/${publicToken}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.API_KEY}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          setAccountToken(data.account_token);
        });
    },
  });

  return (
    <div className="App">
      <header className="App-header">
        <button onClick={open} disabled={!isReady}>
          {" "}
          Click Me{" "}
        </button>
        <div>
          {" "}
          {accountToken !== undefined
            ? "Successfully Exchanged Public Token For Account Token"
            : undefined}
        </div>
      </header>
    </div>
  );
};

export default App;
